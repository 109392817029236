import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import ContentReverse from '../components/ContentReverse';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('navigationWayfindingPage');

    const icons = [{
        icon: 'icon-map5',
        text: t('TITLE_ICON_STEP')
    },
    {
        icon: 'icon-mobile-devices',
        text: t('TITLE_ICON_FUSION_TECH')
    },
    {
        icon: 'icon-plugin',
        text: t('TITLE_ICON_SIMPLE_TOOLS')
    }];

    const right = [

        {textRight: t('TITLE_VISUAL_COMBINE')},
        {
            icon: 'icon-layers1',
            text: t('DESCRIPTION_MULTILEVEL')
        },
        {
            icon: 'icon-map5',
            text: t('DESCRIPTION_TRANSITION')
        },
        {
            icon: 'icon-my_location',
            text: t('DESCRIPTION_SYSTEMS')
        }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_NAVIGATION')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_NAVIGATION')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos title={t('THEY_USE_NAVIGATION')}
                        size='large'
                        customersLogos={[
                            {image: '/images/home/logo-ministere-culture.png'},
                            {image: '/images/products/wemap-navigation/logo-colmar.png'},
                            {image: '/images/products/wemap-navigation/logo_jc_decaux.png'},
                            {image: '/images/products/indoor/logo_ratp.svg'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_SUPER_POWER')}
                        videoUrl='/images/products/wemap-navigation/ar-outdoor-wemap.mp4'
                        pictureRight={data.whiteMobile.childImageSharp}
                        titleButton={t('TITLE_BUTTON_GET_STARTED')}
                        altDescription='gif of iphone with navigation in augmented reality'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_SUPER_POWER')
                            },
                            {
                                icon: 'icon-map-marker',
                                text: t('DESCRIPTION_WALKING_ITINERARY')
                            },
                            {
                                icon: 'icon-mobile',
                                text: t('DESCRIPTION_IMMERSIVE_NAVIGATION')
                            }

                        ]}
                    />

                    <VideoIframe>
                        <IframeVideo title="youtube"
                            width="101%"
                            height="100%"
                            src="https://www.youtube.com/embed/GHIgTQRytfE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </IframeVideo>
                    </VideoIframe>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_WAYFINDING_TECHNOLOGY')}
                            text={t('DESCRIPTION_WAYFINDING_TECHNOLOGY')}
                            titleButton={t('TITLE_BUTTON_TALK_PROJECT')}
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <ContentReverse
                        title={t('TITLE_NAVIGATION')}
                        videoUrl='/images/products/wemap-navigation/multi-etage-video-v4.mp4'
                        pictureLeftIphoneX={data.whiteMobile.childImageSharp}
                        altDescription='gif of augmented reality during a change in levels'
                        titleButton={t('TITLE_BUTTON_GET_STARTED')}
                        rightIcons={right}
                        urlFooter='/contact/'
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_LEVERAGE_POWER')}
                        pictureRight={data.webApps.childImageSharp}
                        altDescription='mobile with connexion screen to media'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-paragraph-left',
                                text: t('DESCRIPTION_CONNECT_CONTENT')
                            },
                            {
                                icon: 'icon-check-circle',
                                text: t('DESCRIPTION_CREATE_DEPLOY_MAP')
                            },
                            {
                                icon: 'icon-add-solid',
                                text: t('DESCRIPTION_CONTENT_SERVICE')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_MONETIZE')
                            }
                        ]}
                    />

                    <BannerPicture backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with person shopping and navigating with mobile'
                        titleBannerPicture={t('TITLE_BANNER_SHOPPING')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/events-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  events: file(relativePath: {eq: "industries/events/wemap-events.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 330, layout: FIXED)
    }
  }
  whiteMobile: file(
    relativePath: {eq: "products/wemap-ar/white_mobile_augmented-reality.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 320, layout: FIXED)
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  webApps: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  banner: file(
    relativePath: {eq: "products/wemap-navigation/banner-wemap-retail-shop.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
